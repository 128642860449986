import { Link } from "gatsby"
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <section className="mt-70px pt-16 px-30px mb-16">
      <div className="max-w-600px mx-auto">
        <h1 className="text-3xl text-primary mb-10px">Not found, error 404</h1>
        <p className="text-lg text-primary">
          The page you are looking for no longer exists. Perhaps you can return
          back to the <Link to="/" className="text-link cursor-pointer underline">homepage</Link> and see if you can find what
          you are looking for. 
        </p>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
